import React from "react";
import { SideNavItem } from "./SideNavItem";
import {
  faSignOutAlt,
  faHome,
  faSlidersH,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../hooks";

export const SideNav = ({ user }) => {
  const { logout } = useUserContext();
  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    navigate("/login");
  };
  return (
    <div className="sidenav">
      <ul>
        <li>
          <SideNavItem title="Dashboard" icon={faHome} to="dashboard" />
        </li>
        {(user.is_superuser || user.is_staff) && (
          <li>
            <SideNavItem title="Tasks" icon={faSync} to="tasks" />
          </li>
        )}
        {(user.is_superuser || user.is_staff) && (
          <li>
            <SideNavItem title="Settings" icon={faSlidersH} to="settings" />
          </li>
        )}
        <li>
          <SideNavItem
            title="Log out"
            icon={faSignOutAlt}
            to="login"
            onClick={onLogout}
          />
        </li>
      </ul>
    </div>
  );
};

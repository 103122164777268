import React, { useMemo, useState } from "react";
import { Col, Row, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faReceipt,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Search,
  Pagination,
  Table,
  TableContextProvider,
  useListTable,
  useListQuery,
  DateTimeCell,
} from "components/Tables";
import HorizontalRule from "components/HorizontalRule";
import { toCurrency } from "formatters";
import Button from "components/common/Button";
import { useQuery } from "@tanstack/react-query";
import useClient from "hooks/useClient";

const refund_link = (organisation, XeroID) => {
  return `https://go.xero.com/organisationlogin/default.aspx?shortcode=${organisation?.short_code}&redirecturl=/AccountsPayable/Edit.aspx?InvoiceID=${XeroID}`;
};

export const RefundList = () => {
  const client = useClient();
  const [data, setData] = useState([]);
  const [organisation, setOrganisation] = useState(null);

  useQuery(
    ["organisation"],
    async () => {
      const data = await client.get("connected_organisation").get("data");
      return data;
    },
    {
      onSuccess: (organisation) => {
        setOrganisation(organisation);
      },
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "stripe_id",
        id: "stripe_id",
      },
      {
        Header: "Received",
        accessor: "received",
        id: "received",
        Cell: ({ value }) => {
          if (!value) return "-";
          return <DateTimeCell value={value} />;
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        id: "amount",
        Cell: ({ row, value }) => {
          if (!value) return "-";
          return toCurrency(value / 100, row?.original?.currencyCode || "EUR");
        },
      },
      {
        disableSortBy: true,
        Header: () => <div className="text-center">Sent to Xero</div>,
        Cell: ({ row }) => {
          return (
            <div className="flex justify-center">
              <FontAwesomeIcon
                className={
                  !row.original.XeroID ? "text-red-700" : "text-green-700"
                }
                icon={row.original.XeroID ? faCheckCircle : faTimesCircle}
              />
            </div>
          );
        },
        accessor: "PaymentID",
        id: "PaymentID",
      },
      {
        disableSortBy: true,
        accessor: "see_on_xero",
        id: "see_on_xero",
        Header: () => <div className="text-center"></div>,
        Cell: ({ row }) => {
          return (
            <div className="flex justify-center">
              <Button
                color="primary"
                size="sm"
                disabled={!row.original.XeroID || !organisation}
                onClick={() =>
                  window.open(
                    refund_link(organisation, row.original.XeroID),
                    "_blank"
                  )
                }
              >
                <FontAwesomeIcon className="mr-2" icon={faExternalLinkAlt} />{" "}
                See on Xero
              </Button>
            </div>
          );
        },
      },
    ],
    [organisation]
  );

  const table = useListTable({
    columns,
    data,
    initialState: {
      pageSize: 50,
    },
  });

  const { isFetching } = useListQuery({
    setData,
    table,
    url: "refund",
  });

  return (
    <div className="flex-1 overflow-x-hidden">
      <Container className="container-a mx-auto">
        <h2 className="heading-b mb-4">
          <FontAwesomeIcon icon={faReceipt} className="mr-4" />
          Stripe Refunds
        </h2>
      </Container>
      <HorizontalRule className="-mt-4" />
      <Container className="container-a mx-auto">
        <TableContextProvider table={table}>
          <Row form>
            <Col>
              <Search placeholder={"Search..."} isFetching={isFetching} />
            </Col>
          </Row>
          <Table size="sm" hover striped />
          <Pagination />
        </TableContextProvider>
      </Container>
    </div>
  );
};

export default RefundList;

import React, { useState } from "react";
import { HorizontalRule } from "../../components";
import { Container, Nav, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSlidersH,
  faList,
  faCalculator,
  faPiggyBank,
} from "@fortawesome/free-solid-svg-icons";
import XeroSettings from "./XeroSettings";
import Payouts from "./Payouts";
import {
  AnthologyProgramXeroLinks
} from "../../features/anthology_program_xero_links/AnthologyProgramXeroLinks";
import {
  AnthologyBillingCodeXeroLinks
} from "../../features/anthology_billing_code_xero_links/AnthologyBillingCodeXeroLinks";
const TABS = [
  {
    label: (
      <div>
        <FontAwesomeIcon icon={faList} className="mr-2" /> Program Mapping{" "}
      </div>
    ),
    value: "program_mapping",
    link: "Program Mapping",
  },
  {
    label: (
      <div>
        <FontAwesomeIcon icon={faPiggyBank} className="mr-2" /> Billing Code Mapping{" "}
      </div>
    ),
    value: "billing_code_mapping",
    link: "Billing Code Mapping",
  },
  {
    label: (
      <div>
        <FontAwesomeIcon icon={faCalculator} className="mr-2" /> Xero Connection{" "}
      </div>
    ),
    value: "xero_connection",
    link: "xero-connection",
  },
];

export const Settings = () => {
  const [activeTab, setActiveTab] = useState(TABS[0].value);
  return (
    <div className="flex-1 overflow-x-hidden">
      <Container className="container-a mx-auto">
        <h2 className="heading-b mb-4">
          <FontAwesomeIcon icon={faSlidersH} className="mr-4" />
          Settings
        </h2>
      </Container>
      <HorizontalRule className="-mt-4" />
      <Container className="container-a mx-auto">
        <Nav tabs className="nav container">
          {TABS.map((e, idx) => (
            <NavItem key={idx}>
              <NavLink
                active={activeTab === e.value}
                onClick={() => setActiveTab(e.value)}
                className="nav-link"
                to={e.link}
              >
                {e.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        {activeTab === "xero_connection" && <XeroSettings />}
        {activeTab === "program_mapping" && <AnthologyProgramXeroLinks />}
        {activeTab === "payouts" && <Payouts />}
        {activeTab === "billing_code_mapping" && <AnthologyBillingCodeXeroLinks />}
      </Container>
    </div>
  );
};

export default Settings;

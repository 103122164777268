import React, {Suspense, useMemo, useRef} from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import i18n from "./config/i18n";
import ErrorBoundary from "./components/ErrorBoundary";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { RecoilRoot, useTransactionObservation_UNSTABLE } from "recoil";
import { persistedAtoms } from "./atoms";
import { UserAtomEffect } from "./atomEffects";

import "react-toastify/dist/ReactToastify.min.css";
import {QueryClient, QueryClientProvider as BaseQueryClientProvider} from "@tanstack/react-query";
import {useClient} from "./hooks";

const PersistenceObserver = () => {
  useTransactionObservation_UNSTABLE(({ atomValues, modifiedAtoms }) => {
    for (const modifiedAtom of modifiedAtoms) {
      let value = atomValues.get(modifiedAtom);
      if (value === undefined) {
        localStorage.removeItem(modifiedAtom);
      } else {
        localStorage.setItem(modifiedAtom, JSON.stringify(value));
      }
    }
  });
  return null;
};

function QueryClientProvider({ children }) {
  const clientRef = useRef();
  clientRef.current = useClient();

  const queryClient = useMemo(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        useErrorBoundary: true,
        structuralSharing: false,
        queryFn: ({ queryKey }) => (
          clientRef.current.get(...queryKey).get('data')
        )
      }
    }
  }), [clientRef]);

  console.log(queryClient)

  return (
    <BaseQueryClientProvider client={queryClient}>
      {children}
    </BaseQueryClientProvider>
  )
}

export const App = () => {

  return (
    <Suspense fallback={null}>
      <RecoilRoot
        initializeState={({ set }) => {
          for (const atom of persistedAtoms) {
            const key = atom.key.replace(/__withFallback$/, "");

            if (localStorage.hasOwnProperty(key)) {
              try {
                const value = JSON.parse(localStorage[key]);
                set(atom, value);
              } catch (ex) {
                console.error(ex);
              }
            }
          }
        }}
      >
        <PersistenceObserver />
        <I18nextProvider i18n={i18n}>
          <ToastContainer
            hideProgressBar
            pauseOnHover={false}
            pauseOnFocusLoss={false}
          />
          <BrowserRouter>
            <UserAtomEffect />
            <ErrorBoundary>
              <Suspense fallback={null}>
                <QueryClientProvider>
                  <Routes />
                </QueryClientProvider>
              </Suspense>
            </ErrorBoundary>
          </BrowserRouter>
        </I18nextProvider>
      </RecoilRoot>
    </Suspense>
  );
};

export default App;

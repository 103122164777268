import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { Table as BaseTable } from "reactstrap";
import { useTableContext } from "../hooks";

export const Table = ({ table, ...props }) => {
  const _table = useTableContext();
  table = table ?? _table;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    table;
  const defaultPropGetter = () => ({});
  return (
    <BaseTable {...getTableProps()} {...props}>
      <thead>
        {headerGroups.map((headerGroup, hx) => (
          <tr key={hx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                {column.canSort ? (
                  <span className="ml-2">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} />
                    )}
                  </span>
                ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, ix) => {
          prepareRow(row);

          return (
            <tr
              key={ix}
              {...row.getRowProps(
                props.getRowProps ? props.getRowProps(row) : defaultPropGetter
              )}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </BaseTable>
  );
};

import React from "react";
import ReactDOM from 'react-dom/client';
import "./scss/index.scss";
import App from "./App";
import Promise from "bluebird";
import "./registerUnhandledRejections";

import "./config/i18n";

global.Promise = Promise;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
import React from "react";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import classNames from "classnames";

export const SimpleContainer = ({ children, className = "max-w-md" }) => {
  return (
    <div className="min-h-screen bg-white items-center flex flex-col justify-center py-12">
      <div className={classNames(className, "w-full")}>
        <div className="mb-8">
          <Link to="">
            <img className="mx-auto w-auto" src={logo} alt="Logo" />
          </Link>
        </div>
        {children}
      </div>
    </div>
  );
};

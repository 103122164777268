import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SideNavItem = ({ icon, title, to, ...props }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? "sidenav-active" : "sidenav-nav-item"
      }
      to={to}
      {...props}
    >
      <div className="flex items-center">
        {icon ? (
          <FontAwesomeIcon icon={icon} className="mr-3 hidden md:block" />
        ) : null}
        {title}
      </div>
    </NavLink>
  );
};

import {useMemo, useState} from "react";
import { useQuery } from "@tanstack/react-query";
import { useClient } from "../../../hooks";
import { uniqueId } from "lodash";

export const useListQuery = ({
  url,
  table,
  setData,
  refresh_token = null,
  query_params = {},
}) => {
  const { state, setTotal } = table;
  const { limit, offset, ordering, search } = state;
  const client = useClient();
  const [id] = useState(() => uniqueId("useListQuery-"));

  const query = useQuery({
    queryKey: [
      id,
      {
        limit,
        offset,
        ordering,
        search,
        refresh_token,
        ...Object.keys(query_params),
      }
    ],
    queryFn: async () => {
      const data = await client
        .get(url, {
          params: {
            offset,
            limit,
            search,
            ordering,
            ...query_params,
          },
        })
        .get("data");
      return {
        total: data.count,
        data: data.results,
      }
    },
  });

  const { data } = query;

  useMemo(() => {
    if (!data) return;
    setTotal(data.total);
    setData(data.data);
  }, [data, setData, setTotal]);

  return query
};

import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { HorizontalRule } from "../../components";
import useClient from "hooks/useClient";
import ConnectionStatus from "Pages/Organisations/ConnectionStatus";
import Loading from "components/common/Loading";
import { DisplayInfo } from "components";
import { DateTimeCell } from "components/Tables";
export const Home = ({ permissions }) => {
  const client = useClient();
  const { isAdmin } = permissions;
  const [organisation, setOrganisation] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const onMount = useCallback(async () => {
    const [org, stats] = await Promise.all([
      client.get("connected_organisation").get("data"),
      client.get("connected_organisation/statistics").get("data"),
    ]);
    setOrganisation(org);
    setStatistics(stats);
  }, [client]);
  useEffect(() => void onMount(), [onMount]);

  const values = useMemo(() => {
    return [
      ["Organisation Name", organisation?.xero_organisation_name],
      ["Organisation ID", organisation?.xero_organisation_id],
      [
        "Last connection update",
        <DateTimeCell value={organisation?.modified} />,
      ],
      [
        "Last invoice sync",
        <DateTimeCell value={statistics?.last_invoice_sync} />
      ]
    ];
  }, [organisation, statistics]);

  return (
    <div className="flex-1 overflow-x-hidden">
      <Container className="container-a mx-auto">
        <div className="row">
          <h2 className="heading-b mb-4 col">
            <FontAwesomeIcon icon={faHome} className="mr-4" />
            Dashboard
          </h2>
          <div className="col text-right">
            {isAdmin && organisation !== null && (
              <ConnectionStatus item={organisation} />
            )}
          </div>
        </div>
      </Container>
      <HorizontalRule className="-mt-4" />
      <Container className="container-a mx-auto">
        {organisation === null && <Loading />}
        <DisplayInfo values={values} padding="px-1 py-2" />
      </Container>
    </div>
  );
};

export default Home;

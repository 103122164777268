import moment from "moment";
import { upperFirst } from "lodash";

export const CURRENCY = {
  GBP: "\u00A3",
  USD: "\u0024",
  EUR: "\u20AC",
  JPY: "\u00A5",
  SGD: "\u0024",
  CNY: "\u00A5",
};

export const currencySymbol = (cur) => {
  return CURRENCY[cur] || `${cur} `;
};

export const toTime = (v, format = "h [hours] m [minutes]") =>
  moment.duration(v, "minutes").format(format);

export const toDate = (v, format = "DD/MM/YYYY") => moment(v).format(format);

export const toDateTime = (v, format = "DD/MM/YYYY HH:mm") =>
  moment(v).format(format);

export const toDuration = (v) =>
  v ? moment.duration(v, "seconds").format("h:mm", { trim: false }) : "0:00";

export default toDuration;

export const toCurrency = (v, currency = "GBP") => {
  if (Number(v) < 0) return `-\u00A3${Number(-1 * v).toFixed(2)}`;
  return `${currencySymbol(currency)}${Number(v).toFixed(2)}`;
};

export const toDecimal = (v, currency = "GBP") => {
  const val = Math.round(Math.abs(Number(v)));
  return (
    (v < 0 ? "-" : "") +
    currencySymbol(currency) +
    val.toLocaleString("en-US", { maximumFractionDigits: 0 })
  );
};

export const toPercentage = (a, b, decimal_places) => {
  let percent = b === 0 ? 0 : (a / b) * 100;
  return `${Number(percent).toFixed(decimal_places || 0)}%`;
};

export const snakeCaseToString = (x) => {
  let parts = x.split("_");
  return parts.map((p) => upperFirst(p)).join(" ");
};

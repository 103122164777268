import { useController } from "react-hook-form";
import React from "react";

export const Input = ({
  component: Component = "input",
  name,
  defaultValue,
  control,
  rules,
  shouldUnregister,
  ...props
}) => {
  const { field } = useController({
    defaultValue,
    control,
    shouldUnregister,
    name,
    rules,
  });

  return <Component {...field} {...props} />;
};

import { atom } from "recoil";

export const tokenAtom = atom({
  key: "token",
  default: null,
  persistence_UNSTABLE: {
    type: true,
  },
});

export const persistedAtoms = [tokenAtom];

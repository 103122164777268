import classNames from "classnames";
import React from "react";
import XeroBlue from "../../assets/img/connect-blue.svg";
import XeroWhite from "../../assets/img/connect-white.svg";
import DisconnectXeroBlue from "../../assets/img/disconnect-blue.svg";
import DisconnectXeroWhite from "../../assets/img/disconnect-white.svg";

export const XeroButton = ({
  onClick = () => {},
  className = "",
  white = false,
  disconnect = false,
  ...props
}) => {
  let svg;
  if (disconnect) {
    svg = white ? DisconnectXeroWhite : DisconnectXeroBlue;
  } else {
    svg = white ? XeroWhite : XeroBlue;
  }
  return (
    <img
      src={svg}
      className={classNames("xero-button cursor-pointer", className)}
      onClick={onClick}
      {...props}
      alt="Xero Button"
    />
  );
};

export default XeroButton;

import React from "react";

const Aux = (props) => props.children;

export const ConnectionStatus = ({
  item,
  showDisconnect = false,
  onDisconnected,
  ...props
}) => {
  return (
    <Aux {...props}>
      {!item.status ||
        (item.status === "NOT_CONNECTED" && (
          <span className="text-danger text-2xl">Not connected to Xero</span>
        ))}
      {item.status === "LIVE" && (
        <div className="col">
          <span className="row text-success text-2xl">
            Connected to {item.xero_organisation_name || "Xero"}{" "}
          </span>
        </div>
      )}
      {item.status === "TOKEN_EXPIRED" && (
        <div className="col">
          <span className="row text-danger text-2xl">
            Connection to {item.xero_organisation_name || "Xero"} invalid
          </span>
        </div>
      )}
    </Aux>
  );
};

export default ConnectionStatus;

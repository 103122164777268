import React, { useEffect } from "react";
import { useClient } from "../../hooks";
import { Page } from "../../components/common";
import { FormGroup, Table } from "reactstrap";
import { chain, keyBy, orderBy } from "lodash";
import Select from "react-select";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { SubmitButton } from "../../components/react-hook-form";
import { toast } from "react-toastify";
import { useQueries } from "@tanstack/react-query";

const url = "anthology_program_xero_links";
const xeroTrackingCategoriesUrl = `${url}/xero_tracking_categories`;
const programsUrl = `${url}/anthology_programs`;

function selectTrackingOptions(xeroTrackingCategories) {
  const ret = [];

  for (const trackingCategory of xeroTrackingCategories) {
    for (const trackingOption of trackingCategory["Options"]) {
      ret.push({
        ...trackingOption,
        FullName: `${trackingCategory.Name}: ${trackingOption.Name}`,
      });
    }
  }

  return orderBy(ret, "FullName");
}

export function AnthologyProgramXeroLinks() {
  const client = useClient();
  const form = useForm();
  const { setValue, handleSubmit, watch } = form;
  const mapping = watch("mapping");

  const queries = useQueries({
    queries: [
      { queryKey: [xeroTrackingCategoriesUrl], select: selectTrackingOptions },
      { queryKey: [programsUrl] },
      { queryKey: [url] },
    ]
  });
  const [{ data: xeroTrackingOptions }, { data: programs }, { data }] = queries;

  useEffect(() => {
    if (!(xeroTrackingOptions && programs && data)) return;

    const dataByProgramID = keyBy(data, "anthology_program_id");
    const xeroTrackingOptionsByID = keyBy(
      xeroTrackingOptions,
      "TrackingOptionID",
    );

    const mapping = {};

    for (const { Id } of programs) {
      const xeroTrackingOptionId =
        dataByProgramID[Id]?.xero_tracking_option_id || null;
      mapping[Id] = xeroTrackingOptionsByID[xeroTrackingOptionId] || null;
    }

    setValue("mapping", mapping);
  }, [xeroTrackingOptions, programs, data, setValue]);

  async function onSubmit({ mapping }) {
    const payload = chain(mapping)
      .map((v, k) => ({
        anthology_program_id: k,
        xero_tracking_option_id: v?.TrackingOptionID,
      }))
      .filter((i) => i.xero_tracking_option_id)
      .value();
    await client.post(url, payload);
    toast.success("Saved successfully");
  }

  if (!mapping) return null;

  return (
    <Page>
      <FormProvider {...form}>
        <Table size="sm">
          <thead>
            <tr>
              <th>Program</th>
              <th>Xero Tracking</th>
            </tr>
          </thead>
          <tbody>
            {programs.map((i) => (
              <tr key={i.Id}>
                <td>
                  {i.Code}: {i.Name}
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ width: "20rem " }}>
                    <Controller
                      name={`mapping.${i.Id}`}
                      render={(props) => (
                        <Select
                          options={xeroTrackingOptions}
                          getOptionLabel={(i) => i.FullName}
                          getOptionValue={(i) => i.TrackingOptionID}
                          {...props.field}
                        />
                      )}
                    />
                  </FormGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="flex justify-end">
          <SubmitButton onClick={handleSubmit(onSubmit)}>Save</SubmitButton>
        </div>
      </FormProvider>
    </Page>
  );
}

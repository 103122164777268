import React from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import {
  Login,
  Signup,
  Home,
  Xero,
  Settings,
  Anthology,
  Stripe,
  Customers,
} from "./Pages";
import { Page } from "components/common";
import { NotFound } from "./components/NotFound";
import { useUserContext } from "hooks";
import { SimpleContainer } from "components/SimpleContainer";
import { MainContainer } from "components/MainContainer";
import {
  AnthologyBillingCodeXeroLinks
} from "./features/anthology_billing_code_xero_links/AnthologyBillingCodeXeroLinks";
import {
  AnthologyProgramXeroLinks
} from "./features/anthology_program_xero_links/AnthologyProgramXeroLinks";
import {AnthologyTasks} from "./features/anthology_tasks/AnthologyTasks";

const Forbidden = () => {
  return (
    <Page title="Unauthorised">
      You do not have permission to view this page
    </Page>
  );
};

const BaseRoute = ({ test, checkUser, element: Element, ...props }) => {
  const { user } = useUserContext();

  if (checkUser && !user) {
    return <Navigate to="/login" />;
  } else if (test !== undefined && !test) {
    return <Forbidden />;
  } else if (
    (window.location.pathname === "/" || window.location.pathname === "") &&
    user
  ) {
    return <Navigate to="/dashboard" />;
  }
  return <Element {...props} />;
};

const AuthenticatedRoute = ({ element, ...props }) => {
  return <BaseRoute checkUser element={element} {...props} />;
};

export const AppRoutes = () => {
  const { user, permissions } = useUserContext();
  const { isAdmin } = permissions;
  return (
    <Routes>
      <Route
        element={
          <SimpleContainer>
            <Outlet />
          </SimpleContainer>
        }
      >
        <Route path="login" element={<BaseRoute element={Login.Login} />} />
        <Route
          path="register"
          element={<BaseRoute element={Signup.Signup} />}
        />

        <Route
          path="forgotten_password"
          element={<BaseRoute element={Signup.ForgottenPassword} />}
        />

        <Route
          path="xero_connect"
          element={<BaseRoute element={Xero.Connect} />}
        />
      </Route>

      <Route
        element={
          <MainContainer>
            <Outlet />
          </MainContainer>
        }
      >
        <Route
          index
          element={<AuthenticatedRoute element={<Navigate to="dashboard" />} />}
        />

        <Route
          path="dashboard"
          element={
            <AuthenticatedRoute
              user={user}
              permissions={permissions}
              element={Home.Home}
            />
          }
        />

        <Route
          path="tasks"
          element={
            <AuthenticatedRoute
              user={user}
              test={isAdmin}
              element={AnthologyTasks}
            />
          }
        />

        <Route
          path="settings"
          element={
            <AuthenticatedRoute test={isAdmin} element={Settings.Settings} />
          }
        />

        <Route
          path="payments"
          element={
            <AuthenticatedRoute test={isAdmin} element={Anthology.List} />
          }
        />

        <Route
          path="charges"
          element={
            <AuthenticatedRoute test={isAdmin} element={Stripe.ChargeList} />
          }
        />

        <Route
          path="refunds"
          element={
            <AuthenticatedRoute test={isAdmin} element={Stripe.RefundList} />
          }
        />

        <Route
          path="customers"
          element={
            <AuthenticatedRoute test={isAdmin} element={Customers.List} />
          }
        />

        <Route path="anthology_billing_code_xero_links" element={
          <AuthenticatedRoute test={isAdmin} element={AnthologyBillingCodeXeroLinks} />
        } />

        <Route path="anthology_program_xero_links" element={
          <AuthenticatedRoute test={isAdmin} element={AnthologyProgramXeroLinks} />
        } />
      </Route>

      <Route path="*" element={<BaseRoute element={NotFound} />} />
    </Routes>
  );
};

export default AppRoutes;

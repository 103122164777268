import React, { useMemo, useState } from "react";
import { Col, Row, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Search,
  Pagination,
  Table,
  TableContextProvider,
  useListTable,
  useListQuery,
  DateTimeCell,
} from "components/Tables";
import HorizontalRule from "components/HorizontalRule";
import Button from "components/common/Button";
import { useQuery } from "@tanstack/react-query";

const customer_link = (organisation, XeroID) => {
  return `https://go.xero.com/organisationlogin/default.aspx?shortcode=${organisation?.short_code}&redirecturl=/Contacts/View/${XeroID}`;
};

export const List = () => {
  const [data, setData] = useState([]);
  const [organisation, setOrganisation] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "CustomerId",
        id: "CustomerId",
      },
      {
        Header: "Last Updated",
        accessor: "modified",
        id: "modified",
        Cell: ({ value }) => {
          if (!value) return "-";
          return <DateTimeCell value={value} />;
        },
      },
      {
        Header: "First Name",
        accessor: "FirstName",
        id: "FirstName",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Last Name",
        accessor: "LastName",
        id: "LastName",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Email",
        accessor: "Email",
        id: "Email",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Student Number",
        accessor: "StudentNo",
        id: "StudentNo",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        disableSortBy: true,
        accessor: "see_on_xero",
        id: "see_on_xero",
        Header: () => <div className="text-center"></div>,
        Cell: ({ row }) => {
          return (
            <div className="flex justify-center">
              <Button
                color="primary"
                size="sm"
                disabled={!row.original.XeroContactID || !organisation}
                onClick={() =>
                  window.open(
                    customer_link(organisation, row.original.XeroContactID),
                    "_blank"
                  )
                }
              >
                <FontAwesomeIcon className="mr-2" icon={faExternalLinkAlt} />{" "}
                See on Xero
              </Button>
            </div>
          );
        },
      },
    ],
    [organisation]
  );

  const table = useListTable({
    columns,
    data,
    initialState: {
      pageSize: 50,
    },
  });

  const { isFetching } = useListQuery({
    setData,
    table,
    url: "anthology_customer",
  });

  useQuery({
    queryKey: ['connected_organisation'],
    onSuccess: (organisation) => {
      setOrganisation(organisation);
    }
  });

  return (
    <div className="flex-1 overflow-x-hidden">
      <Container className="container-a mx-auto">
        <h2 className="heading-b mb-4">
          <FontAwesomeIcon icon={faUsers} className="mr-4" />
          Anthology Customers
        </h2>
      </Container>
      <HorizontalRule className="-mt-4" />
      <Container className="container-a mx-auto">
        <TableContextProvider table={table}>
          <Row form>
            <Col>
              <Search placeholder={"Search..."} isFetching={isFetching} />
            </Col>
          </Row>
          <Table size="sm" hover striped />
          <Pagination />
        </TableContextProvider>
      </Container>
    </div>
  );
};

export default List;

import React, { useState, useEffect } from "react";
import qs from "qs";
import { Alert } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useClient from "hooks/useClient";
import Loading from "components/common/Loading";

export const Connect = () => {
  const client = useClient();
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = location;

  useEffect(
    () =>
      void (async () => {
        const { state: organisationId } = qs.parse(search);
        try {
          if (organisationId === undefined) {
            const errorMessage = {
              code: 400,
              message: "No organisation details passed in",
            };
            throw errorMessage;
          }
          await client
            .post(
              `connected_organisation/${organisationId}/confirm_connection`,
              { uri: window.location.href }
            )
            .get("data");
          setSuccess(true);
          navigate("/dashboard");
        } catch (ex) {
          const code = ex.response?.data?.code;

          if (code === "duplicate_organisation") {
            setError(
              "You already have an organisation connected to this Xero account."
            );
          } else if (code === "XeroTenantError") {
            setError(
              "You must explicitly select an organisation you want to connect to"
            );
          } else {
            setError("Invalid Xero integration link.");
          }
        }
      })(),
    [client, search, navigate]
  );

  return (
    <div className="max-w-xs mx-auto text-primary">
      {!error && !success && <Loading />}
      {error && <Alert color="danger">{error}</Alert>}
      {success && (
        <Alert color="success">
          <Link to={`/dashboard`}>Connected successfully!</Link>
        </Alert>
      )}
    </div>
  );
};

import React from "react";
import { SideNav } from "./SideNav";
import { useUserContext } from "../hooks";

export const MainContainer = ({ children }) => {
  const { user } = useUserContext();

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <div className="flex flex-1">
        {user ? <SideNav user={user} /> : null}
        {children}
      </div>
    </div>
  );
};

import React from "react";
import { isEmail, isURL } from "validator";
import { isDecimal, isFloat, isInt } from "validator";
import { phoneNumberRegex } from "../regex";
import { Alert } from "reactstrap";

const getValue = (v) => {
  if (v === null || v === undefined) v = "";
  return v.toString();
};

export const validateEmail =
  ({
    required = true,
    requiredError = "required",
    invalidError = "invalid",
  } = {}) =>
  (v) => {
    v = getValue(v);
    if (!required && !v) return null;
    if (required && !v) return requiredError;
    return isEmail(v) ? null : invalidError;
  };

export const validateUrl =
  ({ required = true, ...options } = {}) =>
  (v) => {
    v = getValue(v);
    if (!required && !v) return null;
    return isURL(v, options) ? null : "invalid";
  };

export const validateDecimal =
  ({ required = true, decimal_digits = "0,2", min = 0 } = {}) =>
  (v) => {
    v = getValue(v);
    if (!required && !v) return null;
    const valid = isDecimal(v, { decimal_digits }) && isFloat(v, { min });
    return valid ? undefined : "invalid";
  };

export const validateInt =
  ({ required = true } = {}) =>
  (v) => {
    v = getValue(v);
    if (!required && !v) return null;
    return isInt(v);
  };

export const validate =
  (cb, { required = true, error = "invalid" } = {}) =>
  (v) => {
    v = getValue(v);
    if (!required && !v) return null;
    return cb(v) ? undefined : error;
  };

const isPhoneNumber = ({ value }) => {
  return phoneNumberRegex.test(value);
};

export const validatePhoneNumber =
  ({
    required = true,
    requiredError = "required",
    invalidError = "invalid",
  } = {}) =>
  (v) => {
    v = getValue(v);
    if (!required && !v) return null;
    if (required && !v) return requiredError;
    return isPhoneNumber(v) ? null : invalidError;
  };

export function getPasswordError(ex) {
  const code = ex?.response?.data?.code;
  if (code === "invalid_token") {
    return (
      <Alert color="danger" className="text-sm text-center">
        This link is not valid or has already been used.
      </Alert>
    );
  } else if (code === "expired_token") {
    return (
      <Alert color="info" className="text-sm text-center">
        This link has expired and can no longer be used.
      </Alert>
    );
  } else if (code === "user_exists") {
    return (
      <Alert color="danger" className="text-sm text-center">
        An user with this email already exists
      </Alert>
    );
  } else if (code === "invalid_organisation_token") {
    return (
      <Alert color="danger" className="text-sm text-center">
        This link is not valid.
      </Alert>
    );
  } else if (code === "invalid_password") {
    return (
      <Alert color="danger" className="text-sm text-center">
        The provided password is not strong enough.
      </Alert>
    );
  } else if (code === "password_mismatch") {
    return (
      <Alert color="danger" className="text-sm text-center">
        The provided passwords do not match.
      </Alert>
    );
  } else {
    throw ex;
  }
}

import React, { useMemo, useState } from "react";
import { Col, Row, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faCoins,
  faExternalLinkAlt,
  faCashRegister,
} from "@fortawesome/free-solid-svg-icons";
import {
  Search,
  Pagination,
  Table,
  TableContextProvider,
  useListTable,
  useListQuery,
  DateTimeCell,
} from "components/Tables";
import HorizontalRule from "components/HorizontalRule";
import { toCurrency } from "formatters";
import Button from "components/common/Button";
import { FormProvider, useForm } from "react-hook-form";
import { FormGroup, SubmitButton } from "../../components/react-hook-form";
import { useQuery } from "@tanstack/react-query";
import useClient from "hooks/useClient";
import useModal from "hooks/useModal";
import moment from "moment";
import { Select } from "components/common";
import { toast } from "react-toastify";

const payment_link = (organisation, XeroID) => {
  return `https://go.xero.com/organisationlogin/default.aspx?shortcode=${organisation?.short_code}&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=${XeroID}`;
};

const getDate = () => {
  return new Date(moment().subtract(1, "months")).toISOString();
};

export const ChargeList = () => {
  const client = useClient();
  const [data, setData] = useState([]);
  const [organisation, setOrganisation] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const methods = useForm({
    defaultValues: { payment: null, invoice: null },
    mode: "all",
  });
  const { handleSubmit, reset } = methods;

  useQuery({
    queryKey: ['connected_organisation'],
    onSuccess: (organisation) => {
      setOrganisation(organisation);
    }
  });

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "stripe_id",
        id: "stripe_id",
      },
      {
        Header: "Received",
        accessor: "received",
        id: "received",
        Cell: ({ value }) => {
          if (!value) return "-";
          return <DateTimeCell value={value} />;
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        id: "amount",
        Cell: ({ row, value }) => {
          if (!value) return "-";
          return toCurrency(value / 100, row?.original?.currencyCode || "EUR");
        },
      },
      {
        Header: "Amount captured",
        accessor: "amount_captured",
        id: "amount_captured",
        Cell: ({ row, value }) => {
          if (!value) return "-";
          return toCurrency(value / 100, row?.original?.currencyCode || "EUR");
        },
      },
      {
        Header: "Amount refunded",
        accessor: "amount_refunded",
        id: "amount_refunded",
        Cell: ({ row, value }) => {
          if (!value) return "-";
          return toCurrency(value / 100, row?.original?.currencyCode || "EUR");
        },
      },
      {
        Header: "Description",
        accessor: "description",
        id: "description",
        Cell: ({ value }) => <div>{value}</div>,
        disableSortBy: true,
      },
      {
        disableSortBy: true,
        Header: () => <div className="text-center">Payout paid</div>,
        Cell: ({ row }) => {
          return (
            <div className="flex justify-center">
              <FontAwesomeIcon
                className={
                  !row.original.PaymentID ? "text-red-700" : "text-green-700"
                }
                icon={row.original.PaymentID ? faCheckCircle : faTimesCircle}
              />
            </div>
          );
        },
        accessor: "PaymentID",
        id: "PaymentID",
      },
      {
        disableSortBy: true,
        accessor: "see_on_xero",
        id: "see_on_xero",
        Header: () => <div className="text-center"></div>,
        Cell: ({ row }) => {
          return (
            <div className="flex justify-center">
              <Button
                color="primary"
                size="sm"
                disabled={!row.original.PaymentID || !organisation}
                onClick={() =>
                  window.open(
                    payment_link(organisation, row.original.PaymentID),
                    "_blank"
                  )
                }
              >
                <FontAwesomeIcon className="mr-2" icon={faExternalLinkAlt} />{" "}
                See on Xero
              </Button>
            </div>
          );
        },
      },
    ],
    [organisation]
  );

  const table = useListTable({
    columns,
    data,
    initialState: {
      pageSize: 50,
    },
  });

  const { isFetching } = useListQuery({
    setData,
    table,
    url: "charge",
  });

  useQuery(
    ["invoices"],
    async () => {
      const data = await client
        .get(`invoice?UpdatedDateUTC__gte=${getDate()}`)
        .get("data");
      return data;
    },
    {
      onSuccess: (invoices) => {
        setInvoices(invoices);
      },
    }
  );

  const onSubmit = async (formData, formProps) => {
    try {
      console.log(formData, formProps);
      const payload = {
        payment: formData.payment.id,
        invoice: formData.invoice.InvoiceID,
      };
      await client.post("charge/reconcile", payload);
      toast.success("Charge reconciled with the invoice successfully");
      reset();
      toggle();
    } catch (ex) {
      toast.error("Something went wrong");
    }
  };

  const filteredPayments = useMemo(
    () => data.filter((e) => !(e.PaymentID || e.uploaded_to_xero)),
    [data]
  );

  const { Modal, toggle, ModalHeader, ModalFooter, ModalBody, CloseButton } =
    useModal();

  return (
    <div className="flex-1 overflow-x-hidden">
      <Container className="container-a mx-auto">
        <h2 className="heading-b mb-4">
          <FontAwesomeIcon icon={faCoins} className="mr-4" />
          Stripe Charges
        </h2>
      </Container>
      <HorizontalRule className="-mt-4" />
      <Container className="container-a mx-auto">
        <TableContextProvider table={table}>
          <Row form>
            <Col>
              <Search placeholder={"Search..."} isFetching={isFetching} />
            </Col>
            <Col className="col-auto">
              <Button className="btn btn-mdblue" onClick={() => toggle()}>
                <FontAwesomeIcon icon={faCashRegister} className="mr-2" />{" "}
                Reconcile
              </Button>
            </Col>
          </Row>
          <Table size="sm" hover striped />
          <Pagination />
        </TableContextProvider>
        <Modal className="modal-lg">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalHeader>
                <div className="heading-a">
                  <FontAwesomeIcon icon={faCashRegister} className="mr-2" />{" "}
                  Manual Reconcile
                </div>
              </ModalHeader>
              <ModalBody>
                <FormGroup name="payment" label="Charge">
                  <Select
                    isClearable
                    valueKey="id"
                    options={filteredPayments}
                    getOptionLabel={(v) =>
                      `${toCurrency(
                        v.amount / 100,
                        v.currencyCode || "EUR"
                      )}, ${moment(v.received).format(
                        "DD/MM/YYYY [at] HH:mm"
                      )} [${v.stripe_id}]`
                    }
                    placeholder="Payment..."
                    name="payment"
                    rules={{ required: true }}
                  />
                </FormGroup>
                <FormGroup name="invoice" label="Xero Invoice">
                  <Select
                    isClearable
                    valueKey="InvoiceID"
                    getOptionLabel={(v) =>
                      `Due: ${toCurrency(
                        v.AmountDue,
                        v.currencyCode || "EUR"
                      )} (Total ${toCurrency(
                        v.Total,
                        v.currencyCode || "EUR"
                      )}). Invoice ${v.InvoiceNumber ?? "-"}.  [${v.InvoiceID}]`
                    }
                    options={invoices}
                    placeholder="Invoice..."
                    name="invoice"
                    rules={{ required: true }}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <CloseButton />
                <SubmitButton className="btn-mdblue">Reconcile</SubmitButton>
              </ModalFooter>
            </form>
          </FormProvider>
        </Modal>
      </Container>
    </div>
  );
};

export default ChargeList;

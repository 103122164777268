import { SimpleContainer } from "./SimpleContainer";
import { Card } from "reactstrap";
import React from "react";

export const NotFound = () => {
  return (
    <SimpleContainer>
      <Card body>
        <div className="text-center">
          The page you have requested is not available.
        </div>
      </Card>
    </SimpleContainer>
  );
};

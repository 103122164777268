import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import { useUserContext } from "../../hooks";
import { useForm, FormProvider } from "react-hook-form";
import { FormGroup, SubmitButton } from "../../components/react-hook-form";
import { validateEmail } from "services/validators";

export const Login = () => {
  const methods = useForm({
    defaultValues: {},
    mode: "all",
  });
  const navigate = useNavigate();
  const { handleSubmit, register } = methods;
  const { login } = useUserContext();

  async function onSubmit({ email, password }) {
    try {
      await login(email, password);
    } catch (ex) {
      const status = ex?.response?.status;

      if (status === 400) {
        toast.error("Invalid username or password");
        return;
      } else {
        throw ex;
      }
    }

    setTimeout(() => {
      navigate("/dashboard");
    }, 0);
  }

  return (
    <div className="max-w-xs mx-auto text-primary">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <h2 className="text-xl font-bold mb-2">
              <Trans>Log in</Trans>
            </h2>
          </div>

          <FormGroup label="Email address" className="form-group">
            <input
              {...register("email", {
                required: true,
                validate: validateEmail(),
              })}
              name="email"
              type="email"
              className="form-control"
              autoComplete="email"
              placeholder="Enter email"
            />
          </FormGroup>

          <FormGroup label="Password" className="form-group">
            <input
              {...register("password", {
                required: true,
                validate: (v) => !!v,
              })}
              name="password"
              type="password"
              autoComplete="current-password"
              className="form-control"
              placeholder="Enter password"
            />
          </FormGroup>
          <div className="mb-3">
            <SubmitButton className="w-full" block>
              Login
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Login;

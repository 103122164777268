import { selector } from "recoil";
import { tokenAtom } from "./atoms";
import { createClient } from "./hooks/useClient";
import { AuthenticationFailedError } from "./errors";

export const userSelector = selector({
  key: "user",
  get: async ({ get }) => {
    const token = get(tokenAtom);

    if (!token) return null;

    try {
      return await createClient({ token }).get("profile").get("data");
    } catch (ex) {
      const code = ex.response?.data?.code;

      if (code === "authentication_failed") {
        throw new AuthenticationFailedError(ex);
      }
      throw ex;
    }
  },
});

import { useMemo } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { set } from "lodash";
import { tokenAtom } from "atoms";
import { useResetRecoilState, useRecoilValue } from "recoil";

export const createClient = ({ baseURL = "/api", token, ...props } = {}) => {
  const result = axios.create({
    baseURL,
    ...props,
  });

  result.interceptors.request.use((config) => {
    if (token) {
      set(config, "headers.Authorization", `JWT ${token}`);
    }
    return config;
  });

  return result;
};

export const useClient = ({
  baseURL = "/api",
  sendAuth = true,
  redirectOnUnauthorized = true,
} = {}) => {
  const navigate = useNavigate();
  const resetToken = useResetRecoilState(tokenAtom);
  const token = useRecoilValue(tokenAtom);

  return useMemo(() => {
    const result = createClient({
      baseURL,
      token: sendAuth ? token : undefined,
    });

    result.interceptors.response.use(null, (err) => {
      const status = err?.response?.status;
      if (status === 401 && redirectOnUnauthorized) {
        err.handled = true;
        resetToken();
        navigate("/login");
      }
      throw err;
    });
    return result;
  }, [baseURL, sendAuth, redirectOnUnauthorized, token, resetToken, navigate]);
};

export default useClient;

import { useEffect } from "react";
import { userSelector } from "./selectors";
import { useRecoilValueLoadable, useRecoilCallback } from "recoil";
import { tokenAtom } from "./atoms";
import { AuthenticationFailedError } from "./errors";
import { toast } from "react-toastify";

export const UserAtomEffect = () => {
  const userLoadable = useRecoilValueLoadable(userSelector);
  const { state, contents } = userLoadable;
  const hasError = state === "hasError";

  const onHasError = useRecoilCallback(({ reset }) => () => {
    if (!hasError) return;

    if (contents instanceof AuthenticationFailedError) {
      toast.info("You have been logged out");
      reset(tokenAtom);
    }
  });

  useEffect(onHasError, [onHasError, hasError]);

  return null;
};

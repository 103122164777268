import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import { FormGroup, SubmitButton } from "../../components/react-hook-form";
import { Page } from "components/common";
import useClient from "hooks/useClient";
import Loading from "components/common/Loading";
import XeroButton from "components/common/XeroButton";

export const addOrganisation = async (client) => {
  try {
    const org = await client.get("connected_organisation").get("data");
    window.location.replace(org?.xero_connect_url);
  } catch (e) {
    toast.error("Could not connect to Xero");
  }
};

export const disconnect = async (client, setOrganisation = () => {}) => {
  try {
    const org = await client
      .post("connected_organisation/disconnect")
      .get("data");
    setOrganisation(org);
  } catch (e) {
    toast.error("Could not disconnect to Xero");
  }
};

export const XeroSettings = () => {
  const url = "settings";
  const org_url = "connected_organisation";
  const client = useClient();
  const methods = useForm({
    defaultValues: { name: "" },
    mode: "all",
  });
  const [organisation, setOrganisation] = useState(null);
  const { handleSubmit, register, reset } = methods;

  const onSubmit = async (x) => {
    try {
      const data = await client.patch(`${org_url}`, x).get("data");
      setOrganisation(data);
      toast.success("Changes saved.");
    } catch (e) {
      toast.error("Something went wrong when saving the Xero settings.");
    }
  };

  const load = async () => {
    try {
      const organisation = await client.get(org_url).get("data");
      setOrganisation(organisation);
      reset(organisation);
    } catch (e) {
      toast.error(
        "Something went wrong when trying to load the Xero subsettings"
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMount = useCallback(load, [client, reset, url]);
  useEffect(() => void onMount(), [onMount]);
  if (organisation === null)
    return (
      <Page>
        <Loading />
      </Page>
    );
  if (!organisation?.token)
    return (
      <Page>
        <div className="text-4xl text-primary mt-3">
          <XeroButton white onClick={() => addOrganisation(client)} />
        </div>
      </Page>
    );

  return (
    <Page>
      <div className="row">
        <div className="col heading-b mb-3 flex items-center">
          {organisation.name
            ? organisation.name
            : organisation.xero_organisation_name}
        </div>
        <div className="col flex justify-end items-center">
          <XeroButton
            disconnect
            onClick={() => disconnect(client, setOrganisation)}
          />
        </div>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup className="form-group" label="Name" name="name">
            <input
              className="form-control"
              {...register("name", { validate: (v) => !!v })}
            />
          </FormGroup>
          <div className="w-100 flex justify-end">
            <SubmitButton>Save</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};

export default XeroSettings;
